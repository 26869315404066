<template>
  <v-container fluid>

    <InnerPageBackHeader @back="$router.push({ name: 'roles' })">
      <template v-slot:right>
        <p class="mb-0">
          <small><strong>Created At:</strong> {{ $momentDate(role.created_at) }}</small>
        </p>
      </template>
    </InnerPageBackHeader>

    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <v-card
          :loading="loading"
          outlined
        >
          <v-card-text>
            <h2>{{ role.name }}</h2>
            <v-btn
              class="mt-3"
              color="primary"
              block
              @click="updateRole"
            >
              Update Role
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card
          :loading="loadingPermissions"
          outlined
        >
          <v-card-text>
            <div
              v-for="(permissions, permissionGroup) in groupedPermissions"
              :key="permissionGroup"
            >
              <h2>{{ permissionGroup }}</h2>
              <v-row>
                <v-col
                  v-for="permission in permissions"
                  :key="permission.id"
                  :cols="6"
                  class="py-0 d-flex pr-4"
                >
                  <v-checkbox
                    v-model="role.permissions"
                    :label="permission.name"
                    :value="permission"
                    class="mr-2"
                  />

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="grey"
                        small
                        dense
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ permission.description }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-divider class="mb-6 mt-3" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar success ref="successBar">
      Role updated successfully!
    </SnackBar>
    <SnackBar error multi-line ref="errorBar">
      {{ updateError.message }}
      <span
        v-if="updateError.errors"
        class="d-block"
      >
        <strong
          v-for="(error, key) in updateError.errors"
          :key="key"
          class="ml-2"
        >
          {{ error[0] }}
        </strong>
      </span>
    </SnackBar>

  </v-container>
</template>
<script type="text/javascript">
import InnerPageBackHeader from '@/components/InnerPageBackHeader'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'ShowRole',

  components: {
    InnerPageBackHeader,
    SnackBar
  },

  props: {
    roleId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      savedPermission: [],
      allPermissions: [],
      loading: false,
      loadingPermissions: false,
      updateError: {},
      role: {
        permissions: []
      }
    }
  },

  mounted () {
    this.getPermissions()
    this.getRole()
  },

  computed: {
    groupedPermissions () {
      var permissions = {}
      this.allPermissions.forEach(permission => {
        if (!permissions[permission.group]) {
          permissions[permission.group] = []
        }
        permissions[permission.group].push(permission)
      })
      return permissions
    }
  },

  methods: {
    updateRole () {
      this.loading = true
      this.$api.persist('put', {
        path: `roles/${this.roleId}`,
        object: this.role,
        params: {
          with: ['permissions']
        }
      })
        .then(({ data: role }) => {
          this.$refs.successBar.open()
          this.role = role
          this.updateError = {}
        })
        .catch(error => {
          this.updateError = error
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    },

    getPermissions () {
      this.loadingPermissions = true
      this.$api.get({
        path: 'permissions',
        params: {
          limit: 100 * 1000
        }
      })
        .then(({ data: permissions }) => {
          this.allPermissions = permissions
        })
        .catch(() => {
          //
        })
        .finally(() => { this.loadingPermissions = false })
    },

    getRole () {
      this.loading = true
      this.$api.get({
        path: `roles/${this.roleId}`,
        params: {
          with: ['permissions']
        }
      })
        .then(({ data: role }) => {
          this.role = role
        })
        .catch(() => {

        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
